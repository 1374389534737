<template>
  <v-expansion-panels
    v-model="expanded"
    accordion
    v-bind="$attrs"
  >
    <v-expansion-panel
      v-if="showName"
      class="pb-0"
    >
      <v-card-title>
        {{ drug.name[$i18n.locale] || drug.name.en }}
        <v-spacer />
        <arkscore-gauge
          v-if="showArkScoreGauge"
          :key="'arkscore-gauge-' + drug.score_adverse_reaction"
          :value="drug.score_adverse_reaction"
          :size="36"
          right
        />
      </v-card-title>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-tabs
        v-model="tab"
        color="blue-grey lighten-1"
        grow
        xdark
      >
        <v-tab
          v-for="tabIcon in [
            { icon: 'fal fa-info-circle', text: 'Info' },
            { icon: 'fal fa-mortar-pestle', text: 'Standard Dosing' },
            { icon: 'fak fa-light-user-circle-bolt', text: 'Adverse Reactions' },
            // { icon: 'fal fa-diagram-venn', text: 'Interactions' },
          ]"
          :key="tabIcon.icon"
          :title="tabIcon.text"
          class="px-0"
        >
          <v-icon small>
            {{ tabIcon.icon }}
          </v-icon>
        </v-tab>
      </v-tabs>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card-text>
            <v-row>
              <v-col
                v-if="showArkScoreBar"
                cols="12"
                class="mb-n8"
              >
                <arkscore-card
                  :name="$t('arkscore.adverse_reaction')"
                  :score="drug.score_adverse_reaction"
                  :bar-height="9"
                  class="mt-3 pb-2"
                  full-width
                  blurb="The likelihood of the drug to cause adverse effects to the patient."
                  flat
                />
              </v-col>
              <v-col
                v-if="drug.brand_names"
                cols="12"
              >
                <div class="text-overline text-no-wrap">
                  {{ $t('drug_info.brand_names') }}
                </div>
                {{ drug.brand_names }}
              </v-col>
              <v-col
                v-if="drug.delivery_methods?.length > 0"
                class="mt-n2"
                cols="12"
              >
                <div class="text-overline">
                  {{ $tc('drug_info.delivery_methods', drug.delivery_methods?.length) }}
                </div>
                <v-chip
                  v-for="method in drug.delivery_methods"
                  :key="method"
                  :color="stringColor($delivery_methods.find(m => m.value == method).text)"
                  class="me-1 mb-1 pa-4 pe-3"
                  small
                  label
                >
                  <v-icon
                    left
                    small
                  >
                    {{ $delivery_methods.find(m => m.value == method).icon }}
                  </v-icon>
                  {{ $t($delivery_methods.find(m => m.value == method).text.toLowerCase()) | capitalize }}
                </v-chip>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="text-overline text-no-wrap">
                  {{ $t('Antimicrobial Resistance') }}
                </div>
                <template v-if="drug.resistance && resistancesTested && resistancesTested.some(r => drug.resistance.map(dr => dr.name.en).includes(r))">
                  {{ $t('drug_info.resistance_tested') }}
                </template>
                <template v-else>
                  {{ $t('drug_info.resistance_not_tested') }}
                </template>
              </v-col>
            </v-row>
          </v-card-text>
        </v-tab-item>
        <v-tab-item>
          <v-row
            v-if="standardDosage"
            class="mt-n2"
          >
            <v-col
              v-if="standardDosage.adjustments"
              md="12"
            >
              <div class="text-overline text-no-wrap">
                {{ $t('drug_info.dosage_adjustments') }}
              </div>
              <div class="text-caption mb-3 mt-n2">
                Based on standard dosage of {{ standardDosage.dosage }}
              </div>
              <ul class="ml-n2">
                <li
                  v-for="adjustment, index in sorted_reference_adjustments"
                  :key="index"
                >
                  <b>
                    {{ adjustment.parameter || adjustment.category }}
                    {{ adjustment.modifier != 'range' ? adjustment.modifier : null }}
                    {{ adjustment.modifier != 'range' ? adjustment.value : adjustment.value.join('-') }}
                  </b>
                  {{ adjustment.dosage }}
                </li>
              </ul>
            </v-col>
          </v-row>
          <v-expansion-panel>
            <v-card-text
              v-if="drug.standard_dosages || loading.standard_dosages"
              class="mb-n6 text-overline"
            >
              {{ $t('drug_info.standard_dosing') }}
            </v-card-text>
            <v-skeleton-loader
              :loading="loading.standard_dosages"
              type="list-item-two-line"
            >
              <v-responsive />
            </v-skeleton-loader>
          </v-expansion-panel>
          <v-expansion-panel
            v-for="dosing, index in drug.standard_dosages"
            :key="`standard_dosage_${index}`"
            class="elevation-0 mx-0 px-0 standard_dosages"
            :value="true"
          >
            <v-expansion-panel-header>
              {{ dosing.dosage }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list
                class="mx-n6 mt-n6"
                dense
              >
                <span
                  v-for="category in ['hepatic', 'renal']"
                  :key="category"
                >
                  <v-subheader class="text-capitalize ms-2">{{ category }} adjustments</v-subheader>
                  <v-list-item-group
                    :value="highlightedAdjustments(dosing.adjustments)"
                    multiple
                  >
                    <v-list-item
                      v-for="adjustment, index in adjustmentSort(dosing.adjustments.filter(adjustment => adjustment.category == category))"
                      :key="index"
                      :value="adjustment.id"
                      class="ps-3"
                      color="info"
                    >
                      <v-list-item-avatar
                        min-width="90"
                        class="my-0 me-2"
                      >
                        <v-chip
                          small
                          outlined
                          label
                          class="font-weight-medium px-2"
                        >
                          {{ adjustment.parameter || $options.filters.capitalize(adjustment.category) }}
                          {{ adjustment.modifier != 'range' ? adjustment.modifier : null }}
                          {{ adjustment.modifier != 'range' ? adjustment.value : adjustment.value.join('-') }}
                        </v-chip>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-subtitle class="text-wrap">
                          {{ adjustment.dosage }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </span>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-tab-item>
        <v-tab-item>
          <template
            v-if="drug.adverse_reactions"
            md="auto"
          >
            <v-card-text class="text-overline text-no-wrap">
              {{ $t('drug_info.adverse_reactions') }}
            </v-card-text>

            <v-data-table
              :items="drug.adverse_reactions"
              :headers="[
                { text: 'Adverse Reaction', value: 'text' },
                { text: 'Severity', value: 'details.severity' },
                // { text: 'Incidence', value: 'details.incidence' },
                { text: 'Timing', value: 'details.timing' },
                // { text: 'Management', value: 'interaction.management' },
                { value: 'actions', align: 'end', sortable: false },
              ]"
              no-data-text="No adverse reactions"
              sort-by="text.en"
              fixed-header
              dense
            >
              <template #item.text="{ item }">
                {{ item.text[$i18n.locale] || item.text.en }}
              </template>
              <template #item.details.timing="{ item }">
                <TimingChip
                  :timing="item.details.timing"
                />
              </template>
              <template #item.details.severity="{ item }">
                <InteractionSeverityChip
                  :severity="item.details.severity"
                />
              </template>
            </v-data-table>
          </template>
          <v-col
            v-else-if="drug.common_side_effects"
            md="auto"
          >
            <div class="text-overline text-no-wrap">
              {{ $t('drug_info.side_effects') }}
            </div>
            {{ drug.common_side_effects }}
          </v-col>
        </v-tab-item>
        <v-tab-item>
          <v-row
            justify="space-between"
            class="mt-n2"
          >
            <v-col
              v-if="!standardDosage && !drug.standard_dosages"
              md="auto"
            >
              <div class="text-overline">
                {{ $t('drug_info.dosing_req') }}
              </div>
              <v-icon small>
                fal fa-fw fa-{{ drug.hepatic_dosing ? 'check' : 'times' }}
              </v-icon>
              {{ $t('drug_info.hepatic_dosing') }}
              <br>
              <v-icon small>
                fal fa-fw fa-{{ drug.renal_dosing ? 'check' : 'times' }}
              </v-icon>
              {{ $t('drug_info.renal_dosing') }}
            </v-col>
            <v-col
              v-if="drug.interactions || drug.live_drug_interactions"
              md="auto"
            >
              <div class="text-overline text-no-wrap">
                {{ $t('drug_info.drug_interact') }}
              </div>
              <ul v-if="drug.live_drug_interactions">
                <li
                  v-for="interaction, index in drug.live_drug_interactions.filter((v, i, a) => a.findIndex(x => x.description === v.description) === i)"
                  :key="index"
                >
                  {{ interaction.description }}
                </li>
              </ul>
              <span v-else>
                <v-data-table
                  :items="drug.interactions"
                  :headers="[
                    { text: 'Drug', value: 'name.en' },
                    { text: 'Description', value: 'description' },
                    { text: 'Severity', value: 'severity' },
                    { text: 'Management', value: 'management' },
                  ]"
                  no-data-text="No drug interactions"
                  sort-by="name.en"
                  fixed-header
                  dense
                />
              </span>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
export default {
  components: {
    ArkscoreCard: () => import('@/components/arkscore/ArkscoreCard.vue'),
    ArkscoreGauge: () => import('@/components/arkscore/ArkscoreGauge.vue'),
    TimingChip: () => import('@/components/drug/TimingChip.vue'),
    InteractionSeverityChip: () => import('@/components/drug/InteractionSeverityChip.vue'),
  },
  props: {
    drug: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    standardDosage: {
      type: Object,
      default: null,
    },
    patient: {
      type: Object,
      default: () => ({}),
    },
    resistancesTested: {
      type: Array,
      default: () => ([]),
    },
    showName: Boolean,
    showArkScoreGauge: Boolean,
    showArkScoreBar: Boolean,
  },
  data () {
    return {
      tab: 0,
      loading: {
        standard_dosages: false,
      },
      expanded: 2,
      adjustment_order: [
        "Hepatic",
        "Renal",
        "CrCl",
        "GFR",
        "eGFR",
        "PD",
        "HD",
        "CRRT",
        "CVVH",
        "CVVHD",
        "CVVHDF",
        "Hybrid HD",
        "PIRRT",
      ],
    }
  },
  computed: {
    sorted_reference_adjustments () {
      return this.adjustmentSort(this.standardDosage.adjustments)
    },
  },
  watch: {
    drug: {
      handler: function () {
        if (this.drug.id && (!this.drug.adverse_reactions || !this.drug.interactions || !this.drug.standard_dosages)) {
          this.fetchDrug()
        }
      },
      deep: true,
    },
  },
  methods: {
    fetchDrug() {
      this.loading.standard_dosages = true
      this.loading.adverse_reactions = true
      this.loading.interactions = true
      const promise = this.axios.get('report/drugs/' + this.drug.id)
        return promise.then((response) => {
          this.$set(this.drug, 'standard_dosages', response.data.standard_dosages)
          this.$set(this.drug, 'adverse_reactions', response.data.adverse_reactions)
          this.$set(this.drug, 'interactions', response.data.interactions)
        }).finally(() => {
          this.loading.standard_dosages = false
          this.loading.adverse_reactions = false
          this.loading.interactions = false
        })
    },
    adjustmentSort(adjustments) {
      let sorted_adjustments = adjustments
      return sorted_adjustments.sort((a, b) => this.adjustment_order.indexOf(a.parameter) - this.adjustment_order.indexOf(b.parameter))
    },
    highlightedAdjustments(adjustments) {
      let highlighted = []

      if (!this.patient?.hepatic_failure && !this.patient?.crcl && !this.patient?.gfr && !this.patient?.dialysis) {
        return []
      }

      if (this.patient?.hepatic_failure) {
        highlighted = highlighted.concat(adjustments.filter(adjustment => adjustment.category == 'hepatic').filter(adjustment => adjustment.parameter == this.patient.hepatic_failure || !adjustment.parameter))
      }

      if (this.patient?.dialysis) {
        highlighted = highlighted.concat(adjustments.filter(adjustment => adjustment.parameter == this.patient.dialysis))
      }

      if (this.patient?.crcl > 0) {
        highlighted = highlighted.concat(adjustments.filter(adjustment => adjustment.parameter == 'CrCl').filter(adjustment => {
          let modifier = adjustment.modifier
          if (modifier == '≥') {
            modifier = '>='
          } else if (modifier == '≤') {
            modifier = '<='
          }
          if (adjustment.modifier == 'range') {
            return this.patient.crcl >= adjustment.value[0] && this.patient.crcl <= adjustment.value[1]
          } else {
            return eval(`${this.patient.crcl} ${modifier} ${adjustment.value}`)
          }
        }))
      }

      if (this.patient?.gfr > 0) {
        highlighted = highlighted.concat(adjustments.filter(adjustment => ['GFR','eGFR'].includes(adjustment.parameter)).filter(adjustment => {
          let modifier = adjustment.modifier
          if (modifier == '≥') {
            modifier = '>='
          } else if (modifier == '≤') {
            modifier = '<='
          }
          if (adjustment.modifier == 'range') {
            return this.patient.gfr >= adjustment.value[0] && this.patient.gfr <= adjustment.value[1]
          } else {
            return eval(`${this.patient.gfr} ${modifier} ${adjustment.value}`)
          }
        }))
      }

      // if (this.patient && (this.patient.crcl || this.patient.gfr)) {
      //   adjustments = adjustments.filter(adjustment => {
      //     if (this.patient.gfr && this.patient.gfr > 0) {
      //       if (adjustment.parameter == 'Renal') {
      //         return true
      //       } else if (adjustment.parameter != 'GFR' && adjustment.parameter != 'eGFR' && !this.patient.crcl) {
      //         return false
      //       } else {
      //           let modifier = adjustment.modifier
      //           if (modifier == '≥') {
      //             modifier = '>='
      //           } else if (modifier == '≤') {
      //             modifier = '<='
      //           }
      //         if (adjustment.modifier == 'range') {
      //           return this.patient.gfr >= adjustment.value[0] && this.patient.gfr <= adjustment.value[1]
      //         } else {
      //           return eval(`${this.patient.gfr} ${modifier} ${adjustment.value}`)
      //         }
      //       }
      //     }

      //     if (this.patient.crcl && this.patient.crcl > 0) {
      //       if (adjustment.parameter == 'Renal') {
      //         return true
      //       } else if (adjustment.parameter != 'CrCl') {
      //         return false
      //       } else {
      //         if (adjustment.modifier == 'range') {
      //           return this.patient.crcl >= adjustment.value[0] && this.patient.crcl <= adjustment.value[1]
      //         } else {
      //           let modifier = adjustment.modifier
      //           if (modifier == '≥') {
      //             modifier = '>='
      //           } else if (modifier == '≤') {
      //             modifier = '<='
      //           }
      //           return eval(`${this.patient.crcl} ${modifier} ${adjustment.value}`)
      //         }
      //       }
      //     }

      //     return true
      //   })
      // }
      return highlighted.map(a => a.id)
    },
  },
}
</script>
<style scoped>
.standard_dosages .v-chip.v-size--small {
  width: 86%;
}
.v-data-footer {
  margin-right: unset;
}
</style>
