<template>
  <material-stats-card
    v-bind="$attrs"
    color="deep-orange lighten-1"
    icon="fal fa-file-magnifying-glass"
    :title="$t('charts.lab_results_waiting')"
    :value="Object.values(statuses.lab_results).length ? Object.values(statuses.lab_results).reduce((a, b) => a + b) : 0"
    sub-icon="fal fa-badge-check"
    :loading="loading.statuses"
    @refresh="fetchStatuses(displayedStatuses)"
  >
    <template #sub-text>
      <router-link
        v-for="(count, status) in statuses.lab_results"
        :key="status"
        :to="{ name: 'Lab Results', query: { status: [ status ] } }"
        class="my-n1 mr-1"
      >
        <status-chip
          :key="status"
          :status="status"
          :text="count"
          dot
        />
      </router-link>
    </template>
  </material-stats-card>
</template>
<script>
import { mapActions, mapState } from 'vuex';

export default {
  components: {
    StatusChip: () => import('@/components/StatusChip.vue'),
  },
  computed: {
    ...mapState('charts', ['loading', 'statuses']),
    displayedStatuses () {
      let statuses = [
        'new',
        'high confidence',
        'quality assurance',
        'pending approval',
        'needs review',
        'needs translation',
      ]

      if (this.$auth.check({ lab_results: ['extract','*'] })) {
        statuses.push('data extraction')
      }

      if (this.$auth.check({ allergens: ['edit','*'], diagnoses: ['edit','*'], organisms: ['edit','*'], resistances: ['edit','*'] })) {
        statuses.push('unclassified data')
      }

      if (this.$auth.check({ clients: ['edit','*'] })) {
        statuses.push('contact lab')
      }

      return statuses
    },
  },
  created () {
    this.fetchStatuses(this.displayedStatuses)
    // Fetch statuses every 2 minutes
    this.interval = setInterval(() => {
      this.fetchStatuses(this.displayedStatuses)
    }, 120000)
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  methods: {
    ...mapActions('charts', ['fetchStatuses']),
  },
}
</script>
