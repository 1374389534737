<template>
  <v-chip
    :color="timingColor"
    dark
    x-small
    oxutlined
    label
    class="py-3 px-2"
  >
    <v-icon
      x-small
      class="ms-n1 me-2"
    >
      {{ timingIcon }}
    </v-icon>
    {{ $t(timing.toLocaleLowerCase()) | capitalize }}
  </v-chip>
</template>
<script>
export default {
  props: {
    timing: {
      type: String,
      default: 'moderate',
    },
  },
  computed: {
    timingColor() {
      switch (this.timing.toLowerCase()) {
        case 'rapid':
          return 'red darken-2';
        case 'early':
          return 'deep-orange darken-5';
        case 'delayed':
          return 'light-blue darken-2';
        default:
          return 'grey';
      }
    },
    timingIcon() {
      switch (this.timing.toLowerCase()) {
        case 'rapid':
          return 'fa fa-bolt';
        case 'early':
          return 'fa fa-sun';
        case 'delayed':
          return 'fa fa-clock';
        default:
          return 'fa fa-question';
      }
    },
  },
}
</script>
