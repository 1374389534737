<template>
  <v-row
    no-gutters
    class="pt-1"
    align="center"
  >
    <v-col class="pe-1">
      <v-select
        v-model.number="patient.dialysis"
        :label="$t('Dialysis')"
        :items="[
          'PD',
          'HD',
          'CRRT',
          'CVVH',
          'CVVHD',
          'CVVHDF',
          'Hybrid HD',
          'PIRRT',
        ]"
        class="text-caption"
        persistent-placeholder
        outlined
        hide-details
        dense
      />
    </v-col>
    <v-col class="px-1">
      <v-text-field
        v-model.number="patient.crcl"
        label="CrCl"
        persistent-placeholder
        outlined
        hide-details
        dense
      />
    </v-col>
    <v-col class="ps-1">
      <v-text-field
        v-model.number="patient.gfr"
        label="GFR"
        persistent-placeholder
        outlined
        hide-details
        dense
      />
    </v-col>
    <v-col
      cols="auto"
      class="align-center"
    >
      <v-dialog
        v-model="dialog"
        max-width="360"
        width="auto"
      >
        <template #activator="{ on }">
          <v-btn
            icon
            rounded
            tile
            class="px-0"
            color="grey darken-1"
            small
            v-on="on"
          >
            <v-icon small>
              fa-duotone fa-solid fa-calculator
            </v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="pe-4 text-capitalize">
            {{ $t('Calculator', { type: $t('renal_function') }) }}
            <v-spacer />
            <v-btn
              icon
              tile
              right
              color="grey"
              class="ms-2"
              @click.stop="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text class="pt-2">
            <v-row>
              <!-- <v-col
                cols="12"
                class="mt-5 mb-2"
              >
                <v-select
                  v-model="patient.renal_calc_method"
                  label="Method"
                  dense
                  :items="[
                    { text: 'Creatinine Clearance (CrCl)', value: 'crcl' },
                    { text: 'Glomerular filtration rate (GFR)', value: 'gfr' },
                  ]"
                  hide-details
                />
              </v-col> -->
              <v-col cols="6">
                <v-select
                  :label="$tc('gender', 1) | capitalize"
                  :value="patient.gender"
                  :items="[
                    { text: $t('male'), value: 'male' },
                    { text: $t('female'), value: 'female' },
                    { text: $t('unknown'), value: 'unknown' }
                  ]"
                  class="text-capitalize"
                  readonly
                  hide-details
                />
              </v-col>
              <v-col>
                <v-select
                  v-model="patient.race"
                  :label="$t('race') | capitalize"
                  :items="[
                    { text: $options.filters.capitalize($t('white')), value: 'white' },
                    { text: $options.filters.capitalize($t('black')), value: 'black' },
                    { text: $options.filters.capitalize($t('asian')), value: 'asian' },
                    { text: $options.filters.capitalize($t('hispanic')), value: 'hispanic' },
                    { text: $options.filters.capitalize($t('other')), value: 'other' },
                  ]"
                  hide-details
                  persistent-placeholder
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model.number="patient.weight"
                  :label="$t('Weight')"
                  type="number"
                  hide-spin-buttons
                  hide-details
                  persistent-placeholder
                >
                  <template #append>
                    <v-btn-toggle
                      v-model="weight_unit"
                      dense
                      row
                    >
                      <v-btn
                        x-small
                        value="kg"
                        class="text-none px-1"
                      >
                        kg
                      </v-btn>
                      <v-btn
                        x-small
                        value="lbs"
                        class="text-none"
                      >
                        lbs
                      </v-btn>
                    </v-btn-toggle>
                  </template>
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model.number="patient.serum_creatinine"
                  :label="$t('Serum creatinine')"
                  hide-details
                  persistent-placeholder
                >
                  <template #append>
                    <span class="text-caption">mg/dL</span>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider />
          <v-card-actions class="pt-5 pb-4">
            <v-row>
              <v-col class="pe-2">
                <v-text-field
                  label="CrCl (mL/min)"
                  persistent-placeholder
                  :value="CrCl"
                  readonly
                  outlined
                  hide-details
                  dense
                />
              </v-col>
              <v-col class="ps-2">
                <v-text-field
                  label="GFR (mL/min/1.73m²)"
                  persistent-placeholder
                  :value="GFR"
                  readonly
                  outlined
                  hide-details
                  dense
                />
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    props: {
      value: {
        type: Object,
        default: () => ({}),
      },
      patientAge: {
        type: Number,
        default: 0,
      },
      patient: {
        type: Object,
        default: () => ({}),
      },
    },
    data () {
      return {
        dialog: false,
        loading: true,
        weight_unit: 'kg',
      }
    },
    computed: {
      CrCl () {
        if (!this.patient.serum_creatinine || !this.patient.weight) {
          return null
        }
        let crcl = ((140 - this.patientAge) * this.patientWeight) / (this.patient.serum_creatinine * 72)
        if (this.patient.gender === 'female') {
          crcl *= 0.85
        }
        return crcl.toFixed(2)
      },
      GFR () {
        if (!this.patient.serum_creatinine) {
          return null
        }
        let a = this.patient.gender == 'male' ? -0.411 : -0.329
        let k = this.patient.gender == 'male' ? 0.9 : 0.7
        let Scr = this.patient.serum_creatinine
        let gfr = 141 * Math.pow(Math.min(Scr/k, 1), a) * Math.pow(Math.max(Scr/k, 1), -1.209) * Math.pow(0.993, this.patientAge)
        if (this.patient.gender === 'female') {
          gfr *= 1.018
        }
        if (this.patient.race === 'black') {
          gfr *= 1.159
        }
        return gfr.toFixed(2)
      },
      patientWeight () {
        if (this.weight_unit === 'kg') {
          return this.patient.weight
        } else {
          return this.patient.weight * 0.453592
        }
      },
    },
    watch: {
      value: {
        handler () {
          this.$emit('update:value', this.value)
        },
        deep: true,
      },
      CrCl (val) {
        this.$set(this.patient, 'crcl', val)
      },
      GFR (val) {
        this.$set(this.patient, 'gfr', val)
      },
    },
    created () {
    },
    mounted () {
    },
    methods: {
    },
  }
</script>
