<template>
  <v-data-table
    v-bind="$attrs"
    must-sort
    fixed-header
    :show-expand="showExpand"
    :loading="loading"
    :headers="tableHeaders"
    :items="providers"
    item-key="uuid"
    :options.sync="options"
    :server-items-length="totalItems"
    :footer-props="{
      itemsPerPageOptions: [ 15, 50, 100, 200 ],
      itemsPerPageText: `${ $options.filters.capitalize($tc('provider', 2)) } ${ $t('per page') }:`,
      showFirstLastPage: true
    }"
    class="elevation-2"
    :sort-by="sortBy"
    :sort-desc="sortDesc"
    @item-expanded="itemExpanded"
  >
    <template
      v-if="showFilters"
      #top
    >
      <v-row
        justify="space-between"
        class="pt-3 px-3 mb-n3 "
      >
        <v-col md="3">
          <search-bar :value.sync="options.search" />
        </v-col>
        <v-spacer />
        <v-col>
          <location-filter
            :value.sync="options.filter.state"
            :label="$t('state') | capitalize"
            icon="fal fa-route-interstate"
          />
        </v-col>
        <v-col>
          <location-filter
            :value.sync="options.filter.country"
            :label="$t('country') | capitalize"
            type="countries"
            icon="fal fa-globe"
            flag
          />
        </v-col>
        <v-col
          v-if="$auth.check({ clients: ['edit', 'view'] })"
        >
          <client-filter
            key="client-filter"
            :value.sync="options.filter.client"
            :params="{ has: 'providers' }"
          />
        </v-col>
        <v-col
          v-if="$auth.check({ clients: ['edit', 'view', '*'] })"
        >
          <tag-filter
            key="provider-filter"
            :value.sync="options.filter.tags"
            type="provider"
          />
        </v-col>
        <v-col class="shrink">
          <v-btn
            v-if="$auth.check({ providers: ['add', 'edit'] })"
            color="success"
            fab
            small
            xv-on="on"
            class="mt-n5"
            @click="$emit('toggle-right-drawer', {}, true)"
          >
            <v-icon small>
              fal fa-plus
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <template #expanded-item="{ item }">
      <td :colspan="tableHeaders.length">
        <v-row>
          <v-col>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>UUID</v-list-item-subtitle>
                <v-list-item-title>
                  {{ item.uuid }}
                  <v-btn
                    small
                    icon
                    tile
                    color="info"
                    @click="$clipboard(item.uuid)"
                  >
                    <v-icon small>
                      mdi-content-copy mdi-flip-h
                    </v-icon>
                  </v-btn>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col>
            <v-list-item
              v-if="item.address"
              three-line
            >
              <v-list-item-icon>
                <v-icon small>
                  fal fa-map-marker-alt
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle>Address</v-list-item-subtitle>
                <v-list-item-title v-text="item.address" />
                <v-list-item-title>{{ item.city }}, {{ item.state }} {{ item.zip }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="item.email">
              <v-list-item-icon>
                <v-icon small>
                  fal fa-envelope
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle>Email</v-list-item-subtitle>
                <v-list-item-title v-text="item.email" />
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="item.phone">
              <v-list-item-icon>
                <v-icon small>
                  fal fa-phone-alt
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle>Phone</v-list-item-subtitle>
                <v-list-item-title>
                  <a :href="'tel:' + item.phone">{{ item.phone }}</a>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="item.fax">
              <v-list-item-icon>
                <v-icon small>
                  fal fa-fax
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle>Fax</v-list-item-subtitle>
                <v-list-item-title v-text="item.fax" />
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </td>
    </template>
    <template #item.city="{ item }">
      <template v-if="item.city">
        {{ item.city }},
      </template>
      <template v-if="item.state">
        {{ item.state }}
      </template>
    </template>
    <template #item.advanced_asp="{ item }">
      <v-tooltip
        v-if="item.advanced_asp"
        top
      >
        <template #activator="{ on }">
          <v-icon
            color="cyan"
            size="14"
            v-on="on"
          >
            fas fa-star
          </v-icon>
        </template>
        {{ item.advanced_asp.value | moment('L') }}
      </v-tooltip>
    </template>
    <template #item.lab_results_count="{ item }">
      <router-link :to="{ name: 'Lab Results', query: { provider: item.uuid } }">
        {{ item.lab_results_count }}
      </router-link>
    </template>
    <template #item.prescriptions_count="{ item }">
      <router-link :to="{ name: 'Prescriptions', query: { provider: item.uuid } }">
        {{ item.prescriptions_count }}
      </router-link>
    </template>
    <template #item.active_prescriptions_count="{ item }">
      <router-link :to="{ name: 'Precision ASP', query: { provider: item.uuid } }">
        {{ item.active_prescriptions_count }}
      </router-link>
    </template>
    <template #item.asp_sms_alerts="{ item }">
      <v-icon
        small
        :color="item.all_meta.asp_sms_alerts ? 'success' : 'grey'"
      >
        {{ item.all_meta.asp_sms_alerts ? 'fas fa-mobile-signal-out' : 'fal fa-horizontal-rule' }}
      </v-icon>
    </template>
    <template #item.score_cdiff_risk="{ item }">
      <arkscore-gauge
        :key="`arkscore-gauge-${item.score_cdiff_risk}`"
        :value="item.score_cdiff_risk"
      />
    </template>
    <template #item.tags="{ item }">
      <div style="max-width: 300px;">
        <v-chip
          v-for="tag in item.tags"
          :key="tag.id"
          x-small
          class="mr-1 mb-1"
          v-text="tag.name.en"
        />
      </div>
    </template>
    <template #item.last_login="{ item }">
      <span
        v-if="item.last_login"
        class="text-caption"
      >
        {{ item.last_login | moment('L LT') }}
      </span>
      <v-icon
        v-else
        color="grey lighten-1"
      >
        fal fa-horizontal-rule
      </v-icon>
    </template>
    <template #item.actions="{ item }">
      <v-btn
        v-if="$auth.check({ providers: ['edit','contacts'] })"
        small
        ripple
        icon
        plain
        @click="$emit('toggle-right-drawer', item, true)"
      >
        <v-icon>fal fa-ellipsis-v</v-icon>
      </v-btn>
    </template>
    <template #pageText="props">
      Showing {{ props.pageStart }} - {{ props.pageStop }} of {{ props.itemsLength }} providers
    </template>
  </v-data-table>
</template>
<script>
  export default {
    components: {
      SearchBar: () => import('@/components/tables/filters/SearchBar.vue'),
      ClientFilter: () => import('@/components/tables/filters/ClientFilter.vue'),
      LocationFilter: () => import('@/components/tables/filters/LocationFilter.vue'),
      TagFilter: () => import('@/components/tables/filters/TagFilter.vue'),
      ArkscoreGauge: () => import('@/components/arkscore/ArkscoreGauge.vue'),
    },
    props: {
      search: {
        type: String,
        default: null,
      },
      filters: {
        type: Object,
        default: () => ({}),
      },
      showFilters: {
        type: Boolean,
        default: true,
      },
      showExpand: {
        type: Boolean,
        default: true,
      },
      itemsPerPage: {
        type: Number,
        default: 15,
      },
      sortBy: {
        type: String,
        default: 'last_name',
      },
      sortDesc: {
        type: Boolean,
        default: false,
      },
      headers: {
        type: Array,
        default: () => ([]),
      },
    },
    metaInfo () {
      return {
        title: this.$options.filters.capitalize(this.$tc('provider', 2)),
      }
    },
    data () {
      return {
        loading: false,
        options: {
          search: null,
          filter: this.$route.query,
          itemsPerPage: this.itemsPerPage,
        },
        totalItems: null,
        providers: [],
        providerForm: {},
        providerEditDialog: false,
        providerDetails: [],
      }
    },
    computed: {
      tableHeaders () {
        var headers = [
          {
            text: 'NPI',
            value: 'npi',
          },
          {
            text: this.$t('surname'),
            value: 'last_name',
          },
          {
            text: this.$t('first name'),
            value: 'first_name',
          },
        //   {
        //     text: 'Address',
        //     value: 'address',
        //   },
          {
            text: this.$options.filters.capitalize(this.$t('location')),
            value: 'city',
          },
          // {
          //   text: this.$options.filters.capitalize(this.$t('state')),
          //   value: 'state',
          // },
          // {
          //   text: 'ZIP',
          //   value: 'zip',
          // },
          {
            text: this.$options.filters.capitalize(this.$t('phone')),
            value: 'phone',
          },
          {
            text: this.$t('email'),
            value: 'email',
          },
          {
            text: this.$tc('Lab Result', 2),
            value: 'lab_results_count',
          },
          {
            text: 'All Rx',
            value: 'prescriptions_count',
          },
          {
            text: 'Active Rx',
            value: 'active_prescriptions_count',
          },
          {
            text: this.$t('Last Login'),
            value: 'last_login',
          },
          {
            text: 'C.diff Risk',
            value: 'score_cdiff_risk',
            align: 'right',
          },
          {
            text: this.$options.filters.capitalize(this.$tc('tag', 2)),
            value: 'tags',
            sortable: false,
          },
          {
            sortable: false,
            align: 'right',
            value: 'actions',
          },
        ]
        if (!this.$auth.check({ clients: ['view','edit','*'] })) {
          headers = headers.filter(x => x.value && x.value != 'client')
          headers = headers.filter(x => x.value && x.value != 'tags')
          headers = headers.filter(x => x.value && x.value != 'advanced_asp')
          headers = headers.filter(x => x.value && x.value != 'score_cdiff_risk')
        }
        if (!this.$auth.check({ 'lab results': ['view','edit','*'] })) {
          headers = headers.filter(x => x.value && x.value != 'lab_results_count')
        }
        if (!this.$auth.check({ prescriptions: ['view','edit','*'] })) {
          headers = headers.filter(x => x.value && x.value != 'prescriptions_count')
          headers = headers.filter(x => x.value && x.value != 'active_prescriptions_count')
        }
        return headers
      },
    },
    watch: {

      options: {
        deep: true,
        handler () {
          this.fetchProviders()
        },
      },
    },
    mounted() {
      this.$root.$on('fetch-providers', data => {
        this.fetchProviders(data)
      })
    },
    methods: {
      fetchProviders () {
        this.loading = true
        const promise = this.axios.get('providers', {
          params:
            {
              ...{
              page: this.options.page,
              count: this.options.itemsPerPage,
              sort: this.options.sortBy[0],
              order: this.options.sortDesc[0] ? 'desc' : 'asc',
              search: this.options.search,
            },
              ...this.$route.query,
              ...this.filters,
            },
        })

        return promise.then((response) => {
          if (response.data.last_page < response.data.current_page) {
            this.options.page = response.data.last_page
            this.fetchProviders()
            return
          }
          this.$set(this.options, 'page', response.data.current_page)
          this.$set(this.options, 'itemsPerPage', Number(response.data.per_page))
          this.totalItems = response.data.total
          this.providers = response.data.data
        }).catch(error => {
          this.$toast.error(error.response?.data?.message)
        }).finally(() => {
          this.loading = false
        })
      },
      itemExpanded (expanded) {
        if (expanded.value) {
          const promise = this.axios.get('https://npiregistry.cms.hhs.gov/api', {
            params: {
              number: expanded.item.npi,
              version: '2.1',
            },
            headers: {
              'Content-Type': 'text/plain',
            },
            withCredentials: false,
          })

          this.axios({
            method: 'get',
            url: '/user/12345',
            data: {
              firstName: 'Fred',
              lastName: 'Flintstone',
            },
          })

          return promise.then((response) => {
            this.providerDetails[expanded.item.npi] = response.data
          })
        }
      },
    },
  }
</script>
