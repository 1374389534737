<template>
  <v-row>
    <v-col
      cols="12"
      md="4"
    >
      <v-card class="mb-4">
        <v-card-title
          class="text-overline text-no-wrap py-1 grey lighten-3"
        >
          <v-icon
            class="me-1"
            small
          >
            fal fa-fw fa-viruses
          </v-icon>
          {{ $t('Organisms Detected') }}
        </v-card-title>
        <v-card-text
          class="pt-4"
        >
          These are the {{ labResult.organisms.length }} organisms that were detected in the lab sample.
          <template v-if="labResult.organisms.filter(x => x.result.detected && x.significant).length > 0">
            {{ $t('organisms_detected.subtitle', { source: $t(`sources.${labResult.sample_source}`) }) }}
          </template>
        </v-card-text>
      </v-card>

      <arkscore-card
        :name="$t('arkscore.infection_complexity')"
        :score="labResult.score_infection_complexity"
        :bar-height="9"
        :text="$t('arkscore.infection_complexity_text')"
        class="pt-3"
      />
    </v-col>
    <v-col>
      <v-expansion-panels
        focusable
        dense
      >
        <v-expansion-panel
          v-for="(organism, index) in orderBy(labResult.organisms.filter(x => x.result.detected), 'name')"
          :key="index"
        >
          <v-expansion-panel-header
            :color="organism.significant ? 'deep-orange lighten-5' : null"
            :class="{ 'font-weight-medium': organism.significant }"
          >
            <div class="d-flex align-center">
              <v-icon
                v-if="organism.significant"
                small
                class="me-3"
              >
                fas fa-diamond-exclamation
              </v-icon>
              {{ organism.name }}
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row
              class="pt-4"
            >
              <v-col
                v-if="organism.description[$i18n.locale]"
                cols="12"
              >
                {{ organism.description[$i18n.locale] }}
              </v-col>
              <v-col>
                <template v-if="organism.infections.length">
                  <div class="text-overline">
                    {{ $t('Commonly Associated Infections') }}
                  </div>
                  <ul>
                    <li
                      v-for="(infection, id) in organism.infections"
                      :key="id"
                    >
                      {{ infection.name }}
                    </li>
                  </ul>
                </template>
                <template v-if="organism.effective_drugs">
                  <div class="text-overline">
                    {{ $t('Drugs with Known Activity') }}
                  </div>
                  <ul class="ms-n2">
                    <li
                      v-for="(drug, id) in orderBy(organism.effective_drugs, 'name.en')"
                      :key="id"
                    >
                      {{ drug.name[$i18n.locale] || drug.name.en }}
                    </li>
                  </ul>
                </template>
              </v-col>
              <v-col
                v-if="organism.references && organism.references.length"
                cols="12"
                md="8"
              >
                <div class="text-overline">
                  {{ $tc('reference', 2) }}
                </div>
                <reference-links
                  class="mt-n4"
                  :references="organism.references"
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>
<script>
import Vue2Filters from 'vue2-filters';

export default {
  components: {
    ArkscoreCard: () => import('@/components/arkscore/ArkscoreCard.vue'),
    ReferenceLinks: () => import('@/components/reference/ReferenceLinks.vue'),
  },
  mixins: [
    Vue2Filters.mixin,
  ],
  props: {
    labResult: {
      type: Object,
      default: () => ({}),
    },
    resistances: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
