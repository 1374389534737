<template>
  <v-btn-toggle
    v-model="patient.hepatic_failure"
    v-bind="$attrs"
  >
    <v-btn
      v-for="item in [
        { text: 'a', value: 'Class A' },
        { text: 'b', value: 'Class B' },
        { text: 'c', value: 'Class C' },
      ]"
      :key="item.value"
      :value="item.value"
      class="px-2 text-caption text-none"
      small
      icxon
    >
      {{ item.value }}
    </v-btn>
  </v-btn-toggle>
</template>
<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    patientAge: {
      type: Number,
      default: 0,
    },
    patient: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      dialog: false,
      loading: true,
      weight_unit: 'kg',
    }
  },
}
</script>
