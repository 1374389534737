<template>
  <v-autocomplete
    v-model="tag"
    :items="$store.state.selects.tags[type]"
    :loading="loading"
    :search-input.sync="search"
    :label="$tc('tag', 2) | capitalize"
    cache-items
    dense
    multiple
    clearable
    menu-props="offsetY"
    item-text="name.en"
    item-value="name.en"
    @change="$emit('update:value', tag)"
  >
    <template #prepend-inner>
      <v-icon
        small
        class="mt-1"
        color="grey"
      >
        fal fa-tag
      </v-icon>
    </template>
    <template #selection="{ item }">
      <v-chip
        x-small
        class="ml-0"
        v-text="item.name.en"
      />
    </template>
  </v-autocomplete>
</template>
<script>
  export default {
    props: {
      value: {
        type: Array,
        default: () => [],
      },
      type: {
        type: String,
        default: null,
      },
    },
    data () {
      return {
        loading: false,
        search: null,
        tag: this.value,
        tags: [],
      }
    },
    watch: {
      search (val) {
        if (val) {
          this.fetchTags()
        }
      },
      value () {
        this.tag = this.value
      },
    },
    created () {
      if (this.type && this.$store.state.selects.tags[this.type].length === 0) {
        this.fetchTags()
      }
    },
    methods: {
      fetchTags () {
        this.loading = true
        const promise = this.axios.get('admin/tags', {
          params:
            {
              count: 20,
              search: this.search,
              type: this.type,
            },
        })
        return promise.then((response) => {
          this.$store.state.selects.tags[this.type] = response.data.data
        }).catch(error => {
          this.$toast.error(error.response?.data?.message)
        }).finally(() => {
          this.loading = false
        })
      },
    },
  }
</script>
