<template>
  <v-list v-bind="$attrs">
    <v-list-item
      v-if="searchable"
    >
      <v-text-field
        v-model="search"
        prepend-inner-icon="mdi-magnify"
        clearable
        dense
      />
    </v-list-item>
    <v-list-item
      v-for="(reference, id) in sortedReferences"
      :key="id"
      class="px-0"
    >
      <v-list-item-icon
        class="mx-1"
      >
        <v-icon
          key="fa-book"
          small
        >
          fa fa-book
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title
          class="text-wrap text-body-2"
        >
          <span v-html="reference.title" />
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ reference.publish_date | moment('MMMM Y') }}
        </v-list-item-subtitle>
        <!-- <v-list-item-subtitle>
          <a
            :href="reference.url"
            target="_blank"
            v-text="reference.url"
          />
        </v-list-item-subtitle> -->
      </v-list-item-content>
      <v-list-item-action>
        <a
          :href="reference.url"
          target="_blank"
        >
          <v-icon
            key="fa-external-link"
            small
          >
            fa fa-external-link
          </v-icon>
        </a>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>
<script>
export default {
  props: {
    references: {
      type: Array,
      default: () => ([]),
    },
    searchable: Boolean,
  },
  data: () => ({
    search: null,
  }),
  computed: {
    sortedReferences () {
      let sortedReferences = this.references.slice().sort((a,b) => a.publish_date < b.publish_date ? 1 : -1)
      if (this.search) {
        return sortedReferences.filter(x => x.title.toLowerCase().includes(this.search.toLowerCase()))
      } else {
        return sortedReferences
      }
    },
  },
}
</script>
