<template>
  <span class="typewriter-text">
    {{ currentText }}<span class="cursor">&nbsp;</span>
  </span>
</template>
<script>
export default {
  props: {
    messages: {
      type: Array,
      required: true,
    },
    typingSpeed: {
      type: Number,
      default: 100,
    },
    erasingSpeed: {
      type: Number,
      default: 20,
    },
    delayBetweenMessages: {
      type: Number,
      default: 1000,
    },
    randomDelayRange: {
      type: Number,
      default: 500,
    },
  },
  data() {
    return {
      currentText: "", // Text currently being displayed with the typewriter effect
      currentIndex: 0, // Index of the current message in the array
    };
  },
  mounted() {
    this.startTyping(); // Start the process when the component is mounted
  },
  methods: {
    typeMessage(message) {
      let index = 0;

      this.currentText = ""; // Clear current text

      const typeInterval = setInterval(() => {
        if (index < message.length) {
          this.currentText += message[index];
          index++;
        } else {
          clearInterval(typeInterval); // Stop typing this message
          // setTimeout(this.nextMessage, this.delayBetweenMessages + this.getRandomDelay()); // Move to the next message
          setTimeout(() => this.eraseMessage(), this.delayBetweenMessages + this.getRandomDelay());

        }
      }, this.typingSpeed);
    },
    eraseMessage() {
      let index = this.currentText.length;

      const eraseInterval = setInterval(() => {
        if (index > 0) {
          this.currentText = this.currentText.slice(0, -1);
          index--;
        } else {
          clearInterval(eraseInterval); // Stop erasing when all characters are gone
          setTimeout(() => this.nextMessage(), 100);
        }
      }, this.erasingSpeed);
    },
    nextMessage() {
      if (this.currentIndex < this.messages.length) {
        this.typeMessage(this.messages[this.currentIndex]);
        this.currentIndex++;
      }
    },
    startTyping() {
      this.currentIndex = 0; // Reset to the first message
      this.nextMessage(); // Start displaying the first message
    },
    getRandomDelay() {
      return Math.floor(Math.random() * this.randomDelayRange);
    },
  },
};
</script>
<style scopxed>
.typewriter-text {
  font-family: monospace;
  white-space: nowrap;
  overflow: hidden;
}

/* Cursor styling */
.cursor {
  border-left: 2px solid;
  display: inline-block;
  animation: blink 0.5s step-end infinite;
}

/* Blinking cursor animation */
@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
</style>
