<template>
  <v-data-table
    must-sort
    fixed-header
    show-expand
    single-expand
    :loading="loading"
    :headers="headers"
    :items="clients"
    item-key="uuid"
    :expanded.sync="expanded"
    :options.sync="options"
    :server-items-length="totalItems"
    :footer-props="{
      itemsPerPageOptions: [ 15, 30, 50, 100 ],
      itemsPerPageText: `${ $options.filters.capitalize($tc('client', 2)) } ${ $t('per page') }:`,
      showFirstLastPage: true
    }"
    class="elevation-2"
  >
    <template #top>
      <v-row class="pt-3 px-3 mb-n3">
        <v-col
          cols="auto"
        >
          <search-bar :value.sync="options.search" />
        </v-col>
        <v-spacer />
        <v-col>
          <table-filter
            :value.sync="options.filter.api"
            :choices="[
              { text: 'Has API Token', value: true },
              { text: 'No API Token', value: false },
            ]"
            label="API"
            icon="fas fa-key-skeleton"
          />
        </v-col>
        <v-col>
          <location-filter
            :value.sync="options.filter.state"
            :label="$t('state') | capitalize"
            icon="fal fa-route-interstate"
          />
        </v-col>
        <v-col>
          <location-filter
            :value.sync="options.filter.country"
            label="Country"
            type="countries"
            icon="fal fa-globe"
            flag
          />
        </v-col>
        <v-col>
          <table-filter
            :value.sync="options.filter.has"
            :choices="$store.state.selects.services"
            label="Services"
            item-value="id"
            item-text="name"
            icon="fal fa-concierge-bell"
            multiple
          />
        </v-col>
        <v-col>
          <tag-filter
            key="client-filter"
            :value.sync="options.filter.tags"
            type="client"
          />
        </v-col>
        <v-col class="shrink">
          <v-btn
            v-if="$auth.check({ clients: 'edit' })"
            color="success"
            fab
            small
            xv-on="on"
            class="mt-n5"
            xelevation="4"
            @click="$emit('toggle-right-drawer', {}, true)"
          >
            <v-icon small>
              fal fa-plus
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <template #expanded-item="{ item }">
      <td :colspan="headers.length">
        <v-row class="my-0">
          <v-col cols="3">
            <v-hover v-slot="{ hover, props }">
              <v-img
                :key="item.uuid"
                :src="item.logo"
                class="mt-3"
                :height="400"
                max-height="200"
                contain
                v-bind="props"
              >
                <v-expand-transition>
                  <v-btn
                    v-if="hover"
                    x-small
                    color="info"
                    :href="item.logo"
                    fab
                    class="me-n4"
                    elevation="0"
                    absolute
                    right
                    download
                  >
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                </v-expand-transition>
              </v-img>
            </v-hover>
          </v-col>
          <v-col>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>ID</v-list-item-title>
                <v-list-item-subtitle>
                  {{ item.id }}
                  <v-btn
                    small
                    icon
                    tile
                    color="info"
                    title="Copy"
                    @click="$clipboard(item.id)"
                  >
                    <v-icon small>
                      mdi-content-copy mdi-flip-h
                    </v-icon>
                  </v-btn>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>UUID</v-list-item-title>
                <v-list-item-subtitle>
                  {{ item.uuid }}
                  <v-btn
                    small
                    icon
                    tile
                    color="info"
                    title="Copy"
                    @click="$clipboard(item.uuid)"
                  >
                    <v-icon small>
                      mdi-content-copy mdi-flip-h
                    </v-icon>
                  </v-btn>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col>
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title>Address</v-list-item-title>
                <v-list-item-subtitle>{{ item.address }}</v-list-item-subtitle>
                <v-list-item-subtitle>{{ item.city }}, {{ item.state }} {{ item.postcode }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col>
            <v-list-item>
              <v-list-item-icon class="mr-4">
                <v-icon>fal fa-tags</v-icon>
              </v-list-item-icon>
              <v-chip
                v-for="tag in item.tags"
                :key="tag.id"
                class="mr-1"
                small
                v-text="tag.name.en"
              />
            </v-list-item>
            <v-list-item>
              <v-list-item-icon class="mr-4">
                <v-icon>fal fa-bell-concierge</v-icon>
              </v-list-item-icon>
              <v-chip
                v-for="service in item.services"
                :key="service.id"
                class="mr-1"
                small
                v-text="service.name"
              />
            </v-list-item>
          </v-col>
        </v-row>
      </td>
    </template>
    <template
      v-for="(column) in headers.filter(x => x.icon)"
      #[`header.${column.value}`]
    >
      <v-icon
        :key="column.value"
        small
      >
        {{ column.icon }}
      </v-icon>
    </template>
    <template #item.name="{ item }">
      <client-icon
        :key="item.uuid"
        :icon="item.icon"
      />
      <span
        class="ms-3"
        :class="{ 'text--disabled': !item.active }"
      >
        {{ item.name }} {{ item.active ? '' : '(Inactive)' }}
      </span>
    </template>
    <template #item.state="{ item }">
      <span class="mr-1">
        <country-flag
          :country="item.country"
        />
      </span>
      <template v-if="item.city">
        {{ item.city }},
      </template>
      <template v-if="item.state">
        {{ item.state }}
      </template>
      <template v-else>
        {{ $store.state.selects.locations.countries.find(x => x.value === item.country)?.text }}
      </template>
    </template>
    <template #item.providers_count="{ item }">
      <router-link :to="{ name: 'Providers', query: { client: item.uuid } }">
        {{ item.providers_count | numeral('0,0') }}
      </router-link>
    </template>
    <template #item.patients_count="{ item }">
      <router-link :to="{ name: 'Patients', query: { client: item.uuid } }">
        {{ item.patients_count | numeral('0,0') }}
      </router-link>
    </template>
    <template #item.lab_results_count="{ item }">
      <router-link :to="{ name: 'Lab Results', query: { client: item.uuid } }">
        {{ item.lab_results_count | numeral('0,0') }}
      </router-link>
    </template>
    <template #item.reports_count="{ item }">
      <router-link :to="{ name: 'Reports', query: { client: item.uuid } }">
        {{ item.reports_count | numeral('0,0') }}
      </router-link>
    </template>
    <template #item.logo="{ item }">
      <v-chip
        v-if="item.logo"
        small
        class="px-2 text-uppercase"
        outlined
        label
        v-text="item.logo.slice(-3)"
      />
    </template>
    <template #item.contract_date="{ item }">
      <v-tooltip
        v-if="item.contract_date !== null"
        left
      >
        <template #activator="{ on }">
          <v-icon v-on="on">
            fal fa-file-contract
          </v-icon>
        </template>
        {{ item.contract_date | moment('LL') }}
      </v-tooltip>
    </template>
    <template #item.has_api_token="{ item }">
      <v-icon
        v-if="item.has_api_token"
        small
      >
        far fa-key-skeleton
      </v-icon>
    </template>
    <template #item.action="{ item }">
      <v-menu
        v-if="$auth.check({ clients: 'edit' })"
        bottom
        right
        offset-y
      >
        <template #activator="{ on }">
          <v-btn
            small
            ripple
            icon
            plain
            v-on="on"
          >
            <v-icon>fal fa-ellipsis-v</v-icon>
          </v-btn>
        </template>
        <v-list
          class="py-0"
          dense
        >
          <sample-report
            :client="item"
          />
          <v-list-item>
            <monthly-report
              :key="`monthly-lab-result-report-${item.uuid}`"
              title="Monthly Lab Results"
              icon="fal fa-file-spreadsheet"
              :record="item"
              @action="monthlyReport"
              @close="columns = ['facility', 'provider']"
            >
              <template #parameters>
                <v-row>
                  <v-col cols="12">
                    Select columns to include in the report
                  </v-col>
                  <v-col
                    v-for="column in [
                      { text: 'Facility', key: 'facility'},
                      { text: 'Facility ID', key: 'facility_id'},
                      { text: 'Provider', key: 'provider'},
                      { text: 'Provider NPI', key: 'provider_npi'},
                      // { text: 'Patient', key: 'patient_name'},
                    ]"
                    :key="column.key"
                    cols="4"
                    class="py-0 my-0"
                  >
                    <v-checkbox
                      v-model="columns"
                      :label="column.text"
                      :value="column.key"
                    />
                  </v-col>
                </v-row>
              </template>
            </monthly-report>
          </v-list-item>
          <v-list-item>
            <monthly-report
              :key="item.uuid"
              title="Download ASP Report"
              :record="item"
              :range="true"
              @action="monthlyReportASP"
            />
          </v-list-item>
          <v-divider />
          <v-list-item @click="$emit('toggle-right-drawer', item, true)">
            <v-list-item-action class="mr-4">
              <v-icon small>
                fa-kit fa-light-building-pen fa-fw
              </v-icon>
            </v-list-item-action>
            <v-list-item-content><v-list-item-title>Edit Client</v-list-item-title></v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <template #pageText="props">
      Showing {{ props.pageStart }} - {{ props.pageStop }} of {{ props.itemsLength }} clients
    </template>
  </v-data-table>
</template>
<script>
  export default {
    components: {
      SearchBar: () => import("@/components/tables/filters/SearchBar.vue"),
      ClientIcon: () => import("@/components/ClientIcon.vue"),
      MonthlyReport: () => import("@/components/tables/dialogs/MonthlyReport.vue"),
      SampleReport: () => import("@/components/tables/dialogs/SampleReport.vue"),
      TableFilter: () => import("@/components/tables/filters/TableFilter.vue"),
      TagFilter: () => import("@/components/tables/filters/TagFilter.vue"),
      LocationFilter: () => import("@/components/tables/filters/LocationFilter.vue"),
      CountryFlag: () => import('vue-country-flag'),
    },
    props: {
      search: {
        type: String,
        default: null,
      },
    },
    metaInfo: {
      title: 'Clients',
    },
    data () {
      return {
        loading: false,
        options: {
          filter: this.$route.query,
          sortBy: ['name'],
          sortDesc: [false],
        },
        totalItems: null,
        headers: [
          {
            text: 'Name',
            value: 'name',
          },
          // {
          //   text: 'City',
          //   value: 'city',
          // },
          {
            text: 'Location',
            value: 'state',
          },
          // {
          //   text: 'ZIP',
          //   value: 'postcode',
          // },
          {
            text: 'Providers',
            value: 'providers_count',
            icon: 'fal fa-user-doctor',
          },
          {
            text: 'Patients',
            value: 'patients_count',
            icon: 'fal fa-bed',
          },
          {
            text: this.$tc('Lab Result', 2),
            value: 'lab_results_count',
            icon: 'fal fa-microscope',
          },
          {
            text: 'Reports',
            value: 'reports_count',
            icon: 'fal fa-file-chart-line',
          },
          {
            text: 'Logo',
            value: 'logo',
            sortable: false,
          },
          {
            text: 'LIS',
            value: 'all_meta.integration_details.lis',
            sortable: false,
          },
          {
            text: 'API',
            value: 'has_api_token',
            sortable: false,
          },
          {
            sortable: false,
            align: 'end',
            value: 'action',
          },
        ],
        clients: [],
        expanded: [],
        rules: {
          required: value => !!value || this.$t('required'),
          counter: value => value.length <= 20 || 'Max 20 characters',
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          },
        },
        columns: [
          'facility',
          'provider',
        ],
      }
    },
    watch: {
      options: {
        deep: true,
        handler () {
          this.fetchClients()
        },
      },
    },
    mounted() {
      this.$root.$on('fetch-clients', data => {
        this.fetchClients(data)
      })
    },
    methods: {
      fetchClients () {
        this.loading = true;
        const promise = this.axios.get('admin/clients', {
          params: {
            ...{
              page: this.options.page,
              count: this.options.itemsPerPage,
              sort: this.options.sortBy[0],
              order: this.options.sortDesc[0] ? "desc" : "asc",
              search: this.options.search,
            },
            ...this.$route.query,
          },
        });

        return promise.then((response) => {
          if (response.data.last_page < response.data.current_page) {
            this.options.page = response.data.last_page
            this.fetchClients()
            return
          }
          this.$set(this.options, 'page', response.data.current_page)
          this.$set(this.options, 'itemsPerPage', Number(response.data.per_page))
          this.totalItems = response.data.total
          this.clients = response.data.data
        }).catch(error => {
          if (error.response?.data?.message) {
            this.$toast.error(error.response.data.message)
          }
        }).finally(() => {
          this.loading = false
        })
      },
      monthlyReport (client, month) {
        this.$toast.processing('Generating report for ' + client.name)
        const toast = this.$toast.getCmp()
        this.axios({
          url: '/reports/labResults/xls',
          params: {
            client: client.uuid,
            columns: this.columns,
            start: this.$moment(month).startOf('month').format(),
            end:  this.$moment(month).endOf('month').format(),
          },
          method: 'GET',
          responseType: 'blob',
        })
          .then((response) => {
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(new Blob([response.data]))
            link.setAttribute('download', client.name + ' - Monthly Lab Results - ' + this.$moment(month).format('MMM Y') + '.xls')
            document.body.appendChild(link)
            link.click()
            link.remove()
            toast.close()
            this.$toast.success('Report generated')
          })
          .catch(error => {
            toast.close()
            if (error.response?.data?.message) {
            this.$toast.error(error.response.data.message)
          }
          })
      },
      monthlyReportASP (client, month) {
        this.$toast.processing('Requesting report for ' + client.name)
        const toast = this.$toast.getCmp()
        this.axios({
          url: '/reports/labResults/pdf',
          params: {
            client: client.uuid,
            start: this.$moment(Array.isArray(month) ? month[0] : month).startOf('month').format(),
            end:  this.$moment(Array.isArray(month) ? month[1] : month).endOf('month').format(),
          },
          method: 'GET',
          responseType: 'blob',
        })
          .then(() => {
            toast.close()
            this.$toast.success("Request sent. An email will be sent to you when the report is ready.");
          })
          .catch(error => {
            toast.close()
            if (error.response?.data?.message) {
            this.$toast.error(error.response.data.message)
          }
          })
      },
    },
  }
</script>
<style>
.normal-flag {
  vertical-align: bottom;
}
</style>
