var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"pt-1",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"pe-1"},[_c('v-select',{staticClass:"text-caption",attrs:{"label":_vm.$t('Dialysis'),"items":[
        'PD',
        'HD',
        'CRRT',
        'CVVH',
        'CVVHD',
        'CVVHDF',
        'Hybrid HD',
        'PIRRT',
      ],"persistent-placeholder":"","outlined":"","hide-details":"","dense":""},model:{value:(_vm.patient.dialysis),callback:function ($$v) {_vm.$set(_vm.patient, "dialysis", _vm._n($$v))},expression:"patient.dialysis"}})],1),_c('v-col',{staticClass:"px-1"},[_c('v-text-field',{attrs:{"label":"CrCl","persistent-placeholder":"","outlined":"","hide-details":"","dense":""},model:{value:(_vm.patient.crcl),callback:function ($$v) {_vm.$set(_vm.patient, "crcl", _vm._n($$v))},expression:"patient.crcl"}})],1),_c('v-col',{staticClass:"ps-1"},[_c('v-text-field',{attrs:{"label":"GFR","persistent-placeholder":"","outlined":"","hide-details":"","dense":""},model:{value:(_vm.patient.gfr),callback:function ($$v) {_vm.$set(_vm.patient, "gfr", _vm._n($$v))},expression:"patient.gfr"}})],1),_c('v-col',{staticClass:"align-center",attrs:{"cols":"auto"}},[_c('v-dialog',{attrs:{"max-width":"360","width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"px-0",attrs:{"icon":"","rounded":"","tile":"","color":"grey darken-1","small":""}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fa-duotone fa-solid fa-calculator ")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"pe-4 text-capitalize"},[_vm._v(" "+_vm._s(_vm.$t('Calculator', { type: _vm.$t('renal_function') }))+" "),_c('v-spacer'),_c('v-btn',{staticClass:"ms-2",attrs:{"icon":"","tile":"","right":"","color":"grey"},on:{"click":function($event){$event.stopPropagation();_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pt-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{staticClass:"text-capitalize",attrs:{"label":_vm._f("capitalize")(_vm.$tc('gender', 1)),"value":_vm.patient.gender,"items":[
                  { text: _vm.$t('male'), value: 'male' },
                  { text: _vm.$t('female'), value: 'female' },
                  { text: _vm.$t('unknown'), value: 'unknown' }
                ],"readonly":"","hide-details":""}})],1),_c('v-col',[_c('v-select',{attrs:{"label":_vm._f("capitalize")(_vm.$t('race')),"items":[
                  { text: _vm.$options.filters.capitalize(_vm.$t('white')), value: 'white' },
                  { text: _vm.$options.filters.capitalize(_vm.$t('black')), value: 'black' },
                  { text: _vm.$options.filters.capitalize(_vm.$t('asian')), value: 'asian' },
                  { text: _vm.$options.filters.capitalize(_vm.$t('hispanic')), value: 'hispanic' },
                  { text: _vm.$options.filters.capitalize(_vm.$t('other')), value: 'other' },
                ],"hide-details":"","persistent-placeholder":""},model:{value:(_vm.patient.race),callback:function ($$v) {_vm.$set(_vm.patient, "race", $$v)},expression:"patient.race"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('Weight'),"type":"number","hide-spin-buttons":"","hide-details":"","persistent-placeholder":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-btn-toggle',{attrs:{"dense":"","row":""},model:{value:(_vm.weight_unit),callback:function ($$v) {_vm.weight_unit=$$v},expression:"weight_unit"}},[_c('v-btn',{staticClass:"text-none px-1",attrs:{"x-small":"","value":"kg"}},[_vm._v(" kg ")]),_c('v-btn',{staticClass:"text-none",attrs:{"x-small":"","value":"lbs"}},[_vm._v(" lbs ")])],1)]},proxy:true}]),model:{value:(_vm.patient.weight),callback:function ($$v) {_vm.$set(_vm.patient, "weight", _vm._n($$v))},expression:"patient.weight"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.$t('Serum creatinine'),"hide-details":"","persistent-placeholder":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('span',{staticClass:"text-caption"},[_vm._v("mg/dL")])]},proxy:true}]),model:{value:(_vm.patient.serum_creatinine),callback:function ($$v) {_vm.$set(_vm.patient, "serum_creatinine", _vm._n($$v))},expression:"patient.serum_creatinine"}})],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"pt-5 pb-4"},[_c('v-row',[_c('v-col',{staticClass:"pe-2"},[_c('v-text-field',{attrs:{"label":"CrCl (mL/min)","persistent-placeholder":"","value":_vm.CrCl,"readonly":"","outlined":"","hide-details":"","dense":""}})],1),_c('v-col',{staticClass:"ps-2"},[_c('v-text-field',{attrs:{"label":"GFR (mL/min/1.73m²)","persistent-placeholder":"","value":_vm.GFR,"readonly":"","outlined":"","hide-details":"","dense":""}})],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }