<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        sm="10"
        md="5"
        lg="4"
        xl="3"
        class="mx-auto"
      >
        <material-card>
          <v-card-text class="text-center">
            <img
              id="logo"
              src="/img/arkstone-logo-name.svg"
              height="50"
              class="mt-2 mb-4"
            >
            <v-divider class="my-4" />
            Your email address has been verified.
          </v-card-text>
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  export default {
    data () {
      return {
        title: this.$t('Email Address Verified'),
        success: null,
        valid: false,
        loading: false,
        alert: false,
        show_password: false,
        form: {
          email: null,
          password: null,
          token: this.$route.params.token,
        },
        rules: {
          email: [
            v => !!v || 'Please enter your e-mail',
            v => /.+@.+/.test(v) || 'Please enter a valid e-mail address',
          ],
          password: [
            v => !!v || 'Please enter your password',
            v => v?.length >= 8 || 'Password must have a minimum of 8 characters',
          ],
          password_confirmation: [
            v => !!v || 'Please re-enter your password',
            v => v === this.form.password || 'Passwords must match',
          ],
        },
      }
    },
    methods: {
      submitForm () {
        if (this.valid) {
          this.loading = true
          const promise = this.axios.post(this.$route.params.token ? 'auth/reset' : 'auth/password', this.form)
          return promise.then(() => {
            this.alert = false
            if (this.$route.params.token) {
              this.title = 'Success!'
              this.success = {
                message: 'Your password has been reset',
                button: 'Log In',
              }
            } else {
              this.title = 'Email Sent'
              this.success = {
                message: 'We have sent an email to ' + this.form.email + ' with a link to reset your password.',
                button: 'Back to Login',
              }
            }
          }).catch(error => {
            if (error.response) {
              switch (error.response.status) {
                case 401:
                  this.alert = {
                    type: 'error',
                    message: 'Invalid email address',
                  }
                  break
                case 412:
                  this.alert = {
                    type: 'error',
                    message: 'The token is no longer valid',
                  }
                  break
                default:
                  this.alert = {
                    type: 'error',
                    message: error,
                  }
                  break
              }
            }
            return false
          }).finally(() => {
            this.loading = false
          })
        } else {
          this.$refs.passwordForm.validate()
        }
      },
    },
  }
</script>

<style scoped>
#logo {
  margin: 20px;
}
</style>
