<template>
  <v-row>
    <v-col
      cols="12"
      md="4"
    >
      <arkscore-card
        :name="$t('arkscore.antimicrobial_resistance')"
        :score="labResult.score_antimicrobial_resistance"
        :bar-height="9"
        :text="$t('arkscore.antimicrobial_resistance_text')"
        class="pt-3"
      />
    </v-col>
    <v-col>
      <v-card>
        <v-expansion-panels
          focusable
        >
          <!-- <v-expansion-panel>
            <v-card-title class="text-overline text-no-wrap py-1 grey lighten-4">
              <v-icon
                class="me-1"
                small
              >
                fal fa-fw fa-shield-virus
              </v-icon>
              {{ $t('Detected Antibiotic Resistance') }}
            </v-card-title>
            <v-card-text class="pa-0" />
          </v-expansion-panel> -->
          <v-expansion-panel
            v-for="(resistance, index) in orderBy(resistances, 'name.en')"
            :key="index"
          >
            <v-expansion-panel-header ripple>
              {{ resistance.name[$i18n.locale] || resistance.name.en }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row class="pt-4">
                <v-col>
                  <p v-if="resistance.description">
                    {{ resistance.description }}
                  </p>
                  <template v-if="resistance.drugs.length">
                    <div class="text-overline">
                      {{ $t('Drugs in this Category') }}
                    </div>
                    <ul>
                      <li
                        v-for="(drug, id) in resistance.drugs"
                        :key="id"
                      >
                        {{ drug.name[$i18n.locale] || drug.name.en }}
                      </li>
                    </ul>
                  </template>
                </v-col>
                <v-col
                  v-if="resistance.references && resistance.references.length"
                  cols="12"
                  md="8"
                >
                  <div class="text-overline">
                    {{ $tc('reference', 2) }}
                  </div>
                  <reference-links
                    :references="resistance.references"
                  />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import Vue2Filters from 'vue2-filters';

export default {
  components: {
    ArkscoreCard: () => import('@/components/arkscore/ArkscoreCard.vue'),
    ReferenceLinks: () => import('@/components/reference/ReferenceLinks.vue'),
  },
  mixins: [
    Vue2Filters.mixin,
  ],
  props: {
    labResult: {
      type: Object,
      default: () => ({}),
    },
    resistances: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
